import { Routes, Route, Navigate } from "react-router-dom";
import { Login } from "./features/Login";
import { Error } from "./features/Error";
import { Admin } from "./components/admin";
import { Home } from "./features/Home";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      {/* <Route path="/admin" element={<Admin />} /> */}
      <Route path="*" element={<Navigate to='/' repalce={true} />}/>
    </Routes>
  );
}

export default App;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setSmallScreen } from "../redux/appSlice";
import { Main } from "../components/main";

function Home() {
  const dispatch = useDispatch();

  const smallScreen = useSelector((state) => {
    return state.app.smallScreen;
  });

  useEffect(() => {
    document.title = "Lynsis Event & Floral";
    updateScreenSize();
    window.addEventListener('resize', 
      throttle(() => {
        updateScreenSize();
      }, 200)
    )
  }, []);

  function updateScreenSize(){
    const currentScreenSize = window.innerWidth;
    const currenSmallScreen = currentScreenSize < 900;
    dispatch(setSmallScreen(currenSmallScreen));
  }

  function throttle(callbackFn, limit) {
    let wait = false;
    return function () {
      if (!wait) {
        callbackFn.call();
        wait = true;
        setTimeout(() => {
          wait = false;
        }, limit);
      }
    }
  }

  return (
    <div className="home-wrapper">
      <Main />
    </div>
  );
}

export { Home };

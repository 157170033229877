import React from "react";

function Footer(props) {
  return (
    <div className="container-fluid row text-center justify-content-center mx-0 px-0 prevent-select" id="footer" translate="no">
      <p className="mb-0">Lynsis Event & Floral : Copyright © 2023 All rights reserved</p>
    </div>
  );
}

export { Footer };

import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "appSlice",
  initialState: {
    smallScreen: false,
    authentionStatus: {
      loggedIn: false
    },
    languageEN: true,
  },
  reducers: {
    setAuthStatus(state, action) {
      state.authentionStatus = action.payload;
    },
    setLanguageEN(state, action) {
      state.languageEN = action.payload;
    },
    setSmallScreen(state, action) {
      state.smallScreen = action.payload;
    },
  }
});

export const { setAuthStatus, setLanguageEN, setSmallScreen } = appSlice.actions;
export default appSlice.reducer;

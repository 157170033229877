import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CoverPageCard } from "../components/CoverPageCard";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

function Login() {
  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyDjdylqedmMj9XFboTmlQT300HYbyytjjw",
    authDomain: "irisbryan-wedding2023.firebaseapp.com",
    projectId: "irisbryan-wedding2023",
    storageBucket: "irisbryan-wedding2023.appspot.com",
    messagingSenderId: "825504541617",
    appId: "1:825504541617:web:73ffc217941695812eeb90"
  };
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const auth = getAuth();

  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    username: "",
    password: ""
  });
  const [status, setStatus] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const LOCAL_URL = 'http://localhost:3001';
  const LOCAL_TEST = window.location.origin == LOCAL_URL;

  useEffect(() => {
    const currentSessionToken = getCookie('__session');
    if (currentSessionToken){
      navigate('/admin');
    } else {
      console.log("Logging In");
    }
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => {
      return { ...values, [name]: value };
    });
  };

  async function setCookie(name, value, maxAge) {
    const date = new Date(Date.now() + maxAge);
    const expires = "; expires=" + date.toUTCString();
    
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  function getCookie(name) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(';');
      for(let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0)==' ') c = c.substring(1,c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
      }
      return null;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setStatus("submitting");
    let baseUrl = "";
    if (LOCAL_TEST) {
      baseUrl = "http://localhost:5000";
    }
    const userCredential = await signInWithEmailAndPassword(auth, inputs.username, inputs.password);
    const user = userCredential.user;
      
    const idToken = await user.getIdToken();
    const createSession = await fetch(`${baseUrl}/createSession`, {
      method: "GET",
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          withCredentials: true,
          "Access-Control-Allow-Origin": baseUrl,
          Authentication: idToken
      },
    });
    
    const sessionResponse = await createSession.json();
    console.log(sessionResponse);
    if (sessionResponse.success){
      await setCookie('__session', sessionResponse.sessionToken, sessionResponse.options.maxAge);
      setTimeout(navigate("/admin"), 2000);
    }
  }

  return (
    <CoverPageCard>
      <hr />
      {/* Login needs a auth action and a post method */}
      <form className="user" onSubmit={handleSubmit}>
        {status == "failure" && (
          <p className="text-center" style={{ color: "#FF0004" }}>
            Wrong username or password
          </p>
        )}
        <div className="form-group">
          <input
            type="text"
            name="username"
            id="loginUsername"
            className="form-control form-control-user"
            placeholder="Enter Username..."
            value={inputs.username}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            name="password"
            id="loginPassword"
            className="form-control form-control-user"
            placeholder="Password"
            value={inputs.password}
            onChange={handleChange}
            required
          />
        </div>
        <input type="submit" className="btn btn-primary btn-user btn-block" disabled={status == "submitting"} />
      </form>
      <hr />
    </CoverPageCard>
  );
}

export { Login };

import React from "react";
import { useSelector } from 'react-redux';
import { rsvp, main } from '../language';

function MainNav(props) {
  const languageEN = useSelector((state) => {
    return state.app.languageEN;
  });

  let navClass = "navbar navbar-expand-md";
  if (props.screenSize == "sm") {
    navClass += " nav-sm-screen sticky";
  } else if (props.screenSize == "lg") {
    navClass += " nav-lg-screen";
  }

  const language = languageEN ? 'en' : 'ch';
  return (
    <nav className={navClass} id="main-navbar">
      <div className="container prevent-select">
        <button
          className="navbar-toggler main-color"
          type="button"
          data-toggle="collapse"
          data-target="#main-nav"
          aria-controls="main-nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fa fa-bars"></i>
        </button>
        {props.screenSize == "sm" && (
          <React.Fragment>
            <div className="top-nav-text prevent-select" translate="no">
              Lynsis Event & Floral
            </div>
            <div style={{ width: "30.5px" }}>
            </div>
          </React.Fragment>
        )}
        <div className="collapse navbar-collapse justify-content-center align-items-center" id="main-nav">
          <ul className="navbar-nav">
            <li className="nav-item" id="nav-home">
              <a className="nav-link" onClick={() => { window.scrollTo({ top: 0, left: 0 }) }}>
                {languageEN ? 'Home' : rsvp.homePage[language]}
              </a>
            </li>
            {/* <li className="nav-item" id="nav-story">
              <a className="nav-link" onClick={() => {
                let ourStory = document.getElementById("our-story");
                if (!ourStory){
                  ourStory = document.getElementById("coming-soon");
                }
                ourStory.scrollIntoView(true);
              }}>
                {main.ourStory[language]}
              </a>
            </li> */}
            <li className="nav-item" id="nav-faq">
              <a className="nav-link" onClick={() => {
                let faq = document.getElementById("products");
                if (!faq){
                  faq = document.getElementById("coming-soon");
                }
                faq.scrollIntoView({behavior: 'smooth'});
              }}>
                {main.products[language]}
              </a>
            </li>
            <li className="nav-item" id="nav-contact">
              <a className="nav-link" onClick={() => {
                document.getElementById("contact").scrollIntoView({behavior: 'smooth'});
              }}>
                {main.contact[language]}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export { MainNav };

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MuiChipsInput } from 'mui-chips-input';
import { Loader } from "../GeneralComponents";


const LOCAL_URL = ['http://localhost:3001', 'http://127.0.0.1:3001', 'http://192.168.50.185:3001'];
const LOCAL_TEST = LOCAL_URL.includes(window.location.origin);
const DEFAULT_EMPTY_PRODUCT = {
  "name": {
    "ch": "",
    "en": "",
  },
  "tags": [],
  "description": {
    "ch": "",
    "en": "",
  },
  "archived": false,
};

function ProductModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [product, setProduct] = useState({...DEFAULT_EMPTY_PRODUCT});
  const [submitStatus, setSubmitStatus] = useState('pending');
  // submitStatus:  "pending" : Filling form
  //                "submitting" : Submitting
  //                "success" : Submission Completed
  //                "error" : Error submission
  const [modalError, setModalError] = useState('');
  const modalOpened = props.modalOpened;
  const productData = props.productData;

  useEffect(() => {
    // Reset State of Edit Modal
    setSubmitStatus('pending');
    if (props.editProductId != '' && Object.keys(productData).includes(props.editProductId)) {
      setProduct({
        ...productData[props.editProductId],
      });
      props.handleModalOpen(true);
    } else {
      setProduct({
        ...DEFAULT_EMPTY_PRODUCT,
      });
    }
    setModalError('');
  }, [props.editProductId]);

  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: '800px',
    maxHeight: '100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflowY: 'auto',
    p: 2,
  };

  function handleChange(event) {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const nestedKeys = name.split('.');
    if (nestedKeys.length == 2) {
      setProduct((values) => {
        return {
          ...values,
          [nestedKeys[0]]: {
            ...values[nestedKeys[0]],
            [nestedKeys[1]]: value,
          }
        };
      });
    } else {
      setProduct((values) => {
        return {
          ...values,
          [name]: value,
        };
      });
    }

  }

  async function postCreateProduct(data) {
    let baseUrl = "";
    if (LOCAL_TEST) {
      let currentUrl = window.location.origin.slice(0, -5);
      baseUrl = `${currentUrl}:5000`;
    }

    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };
      const url = `${baseUrl}/api/createProduct`;
      const response = await fetch(url, options);
      const result = await response.json();

      return result
    } catch (error) {
      console.error(error);
    }
  }

  async function handleSubmit() {
    setSubmitStatus('submitting');
    const data = product;
    const response = await postCreateProduct(data);
    console.log(response);
    if (response.success) {
      setSubmitStatus('success');
      props.refreshTableData();
    } else {
      setSubmitStatus('error');
    }
  }

  function modalClose() {
    setSubmitStatus('pending');
    setModalError('');
    props.setTargetId('');
    props.handleModalOpen(false);
  }

  if (submitStatus != 'pending') {
    return (
      <Modal
        open={modalOpened}
        onClose={modalClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={boxStyle}>
          <div className='flex-row mb-3 col-12 px-1 text-center'>
            {submitStatus == 'submitting' && <Loader />}
            {submitStatus == 'success' && (
              <div>
                <div style={{ color: 'green', fontSize: '5vw' }}>
                  <FontAwesomeIcon icon='fa-solid fa-circle-check' />
                </div>
                <span>Submission Completed.</span>
              </div>
            )}
            {submitStatus == 'error' && (
              <div>
                <div style={{ color: 'red', fontSize: '5vw' }}>
                  <FontAwesomeIcon icon='fa-solid fa-circle-xmark' />
                </div>
                <span>An error has occured, please try again.</span>
              </div>
            )}
          </div>
          {submitStatus != 'submitting' && (
            <div style={{ marginTop: '20px' }} className='flex-row mb-3 col-12 px-1'>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                <Button variant='contained' color='success' onClick={modalClose}>
                  Close
                </Button>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    );
  }

  return (
    <Modal
      open={modalOpened}
      onClose={modalClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={boxStyle}>
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
          <h3>
            <strong>{`${product?.id ? 'Edit' : 'Create'}`} Product</strong>
          </h3>
        </div>
        <div className='row px-1'>
          <div className='flex-row mb-3 col-6'>
            <TextField
              size='small'
              fullWidth
              type='text'
              name='name.en'
              value={product.name.en}
              onChange={handleChange}
              label="Product Name (EN)"
              variant="outlined"
            />
          </div>
          <div className='flex-row mb-3 col-6'>
            <TextField
              size='small'
              fullWidth
              type='text'
              name='name.ch'
              value={product.name.ch}
              onChange={handleChange}
              label="Product Name (CH)"
              variant="outlined"
            />
          </div>
        </div>
        <div className='flex-row mb-3 col-12 px-1'>
          <MuiChipsInput
            size='small'
            type='text'
            fullWidth
            hideClearAll
            disableDeleteOnBackspace
            name='tags'
            value={product.tags}
            label="Tags"
            variant="outlined"
            onChange={(value) => {
              let tempValues = [...value];
              // Create mock event to pass to actual event handler
              const mockEvent = {
                target: {
                  name: 'tags',
                  value: tempValues,
                },
              };
              handleChange(mockEvent);
            }}
          />
        </div>
        <div className='flex-row mb-3 col-12 px-1'>
          <TextField
            size='small'
            fullWidth
            type='text'
            name='description.en'
            value={product.description.en}
            onChange={handleChange}
            label="description (EN)"
            variant="outlined"
          />
        </div>
        <div className='flex-row mb-3 col-12 px-1'>
          <TextField
            size='small'
            fullWidth
            type='text'
            name='description.ch'
            value={product.description.ch}
            onChange={handleChange}
            label="description (CH)"
            variant="outlined"
          />
        </div>
        {product?.id &&
          <div className='col-12 px-1 mb-3'>
            <div style={{ textAlign: 'center', padding: '5px' }}>
              Archived
              <Switch
                name='archived'
                key={product.archived}
                checked={product.archived}
                onChange={handleChange}
              />
            </div>
          </div>
        }
        <div className='flex-row mb-3 col-12 px-1'>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            <Button variant='outlined' color='error' onClick={modalClose}>
              Cancel
            </Button>
            <Button variant='contained' color='primary' onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export { ProductModal };

import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { useSelector } from 'react-redux';

const LOCAL_URL = ['http://localhost:3001', 'http://127.0.0.1:3001', 'http://192.168.50.185:3001'];
const CLOUD_IMAGE_BASE_ROUTE = 'https://storage.googleapis.com/lynsis-webapp.appspot.com/';
const LOCAL_TEST = LOCAL_URL.includes(window.location.origin);

function Products(props) {
  const smallScreen = useSelector((state) => {
    return state.app.smallScreen;
  });

  const [loadingData, setLoadingData] = useState(true);
  const [productData, setProductData] = useState({});
  const [itemData, setItemData] = useState({});
  const [productList, setProductList] = useState({});

  useEffect(() => {
    getProductData();
  }, []);

  async function getProductData() {
    setLoadingData(true);
    let baseUrl = "";
    if (LOCAL_TEST) {
      let currentUrl = window.location.origin.slice(0, -5);
      baseUrl = `${currentUrl}:5000`;
    }

    const getProductsRequest = fetch(`${baseUrl}/api/getAllProducts`, {
      method: 'GET',
    });
    const getItemsRequest = fetch(`${baseUrl}/api/getAllItems`, {
      method: 'GET',
    });
    const [getProductsResponse, getItemsResponse] = await Promise.all([getProductsRequest, getItemsRequest]);
    const productDocs = await getProductsResponse.json();
    const itemDocs = await getItemsResponse.json();

    setProductData(productDocs.data);
    setItemData(itemDocs.data);

    const itemKeys = Object.keys(itemDocs.data);
    const productKeys = Object.keys(productDocs.data);
    const productIdToItemList = {};
    for (let i = 0; i < productKeys.length; i++) {
      productIdToItemList[productKeys[i]] = [];
    }
    for (let j = 0; j < itemKeys.length; j++) {
      const itemCategory = itemDocs.data[itemKeys[j]]?.productCategory;
      if (itemCategory && itemCategory.length > 0) {
        for (let k = 0; k < itemCategory.length; k++){
          productIdToItemList[itemCategory[k]].push(itemKeys[j]);
        }
      }
    }
    setProductList(productIdToItemList)

    setLoadingData(false);
  }

  return (
    <Box sx={{ width: '100%', height: 'auto', paddingTop: '40px' }} id='products'>
      <div className='col-12 p-0 text-center contact-title prevent-select'>
        Products
      </div>
      <ImageList variant="masonry" gap={8} cols={smallScreen ? 2 : 4} >
        {Object.keys(productList).map((productId, index) => {
          const product = productData[productId];
          const itemsList = productList[productId];
          let imageList = [];
          let priceList = [];
          if (itemsList.length > 0) {
            imageList = itemsList.map((itemId) => {
              return itemData[itemId].images
            })
            priceList = itemsList.map((itemId) => {
              return itemData[itemId].priceInCents
            })
          }
          imageList = imageList.flat();
          let defaultImage = './img/product_no_img.jpg';
          if (imageList.length > 0){
            defaultImage = `${CLOUD_IMAGE_BASE_ROUTE}${imageList[0]}`
          }
          let priceText = 'Please Contact Us and Inquire';
          if (priceList.length > 0){
            const lowestPrice = (Math.min(...priceList)/100).toFixed(2);
            priceText = `From $${lowestPrice}`
          }

          return(
            <ImageListItem key={index}>
              <img
                srcSet={defaultImage}
                src={defaultImage}
                alt={product?.name.en}
                loading="lazy"
              />
               <ImageListItemBar
                title={product?.name.en}
                subtitle={priceText}
                actionIcon={
                  <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    aria-label={`info about ${product?.name.en}`}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          )
        })}
      </ImageList>
    </Box>
  );
}

// const itemData = [
//   {
//     img: 'https://images.unsplash.com/photo-1549388604-817d15aa0110',
//     title: 'Bed',
//     author: 'swabdesign',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1525097487452-6278ff080c31',
//     title: 'Books',
//     author: 'Pavel Nekoranec',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1523413651479-597eb2da0ad6',
//     title: 'Sink',
//     author: 'Charles Deluvio',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3',
//     title: 'Kitchen',
//     author: 'Christian Mackie',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1588436706487-9d55d73a39e3',
//     title: 'Blinds',
//     author: 'Darren Richardson',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1574180045827-681f8a1a9622',
//     title: 'Chairs',
//     author: 'Taylor Simpson',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1530731141654-5993c3016c77',
//     title: 'Laptop',
//     author: 'Ben Kolde',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1481277542470-605612bd2d61',
//     title: 'Doors',
//     author: 'Philipp Berndt',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1517487881594-2787fef5ebf7',
//     title: 'Coffee',
//     author: 'Jen P.',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee',
//     title: 'Storage',
//     author: 'Douglas Sheppard',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62',
//     title: 'Candle',
//     author: 'Fi Bell',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1519710164239-da123dc03ef4',
//     title: 'Coffee table',
//     author: 'Hutomo Abrianto',
//   },
// ];

export { Products };

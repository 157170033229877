import React from "react";
import Slider from "react-slick";
import { Products } from "./MainProducts";
import { Contact } from "./Contact";
import banner1 from '../../banner/001.png';
import banner2 from '../../banner/002.png';


const CLOUD_IMAGE_BASE_ROUTE = 'https://storage.googleapis.com/lynsis-webapp.appspot.com';

function MainBody(props) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div className='w-100'>
      <Slider {...settings}>
        {/*
          bannerList.forEach((bannerItem) => {
            return (
              <div className='d-flex justify-content-center slider-img-wrapper'>
                <img style={{width: '100%', objectFit: 'cover', display: 'flex'}} src='./img/story/story1-1.jpg'></img>
              </div>
            )
          })
        */}
        <div className='d-flex justify-content-center slider-img-wrapper'>
          <img style={{width: '100%', objectFit: 'cover', display: 'flex'}} src={banner1}></img>
        </div>
        <div className='d-flex justify-content-center slider-img-wrapper'>
          <img style={{width: '100%', objectFit: 'cover', display: 'flex'}} src={banner2}></img>
        </div>
      </Slider>
      <Products/>
      <Contact/>
    </div>
  );
}

export { MainBody };

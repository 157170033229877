export const rsvp = {
  request: {
    en: 'REQUEST THE HONOR OF YOUR PRESENCE AT OUR WEDDING RECEPTION',
    ch: '请求您出席我们的婚宴',
  },
  rsvpText: {
    en: 'RSVP',
    ch: '回复请柬',
  },
  rsvpDate: {
    en: '(Respond By Sept 14, 2023)',
    ch: '(2023 年 9 月 14 日之前回复)',
  },
  catering: {
    en: 'Catering Select',
    ch: '婚宴餐饮选择',
  },
  ceremony: {
    en: 'Ceremony Starts',
    ch: '婚礼仪式开始',
  },
  cocktail: {
    en: 'Cocktail Hour',
    ch: '鸡尾酒时间',
  },
  receptionStart: {
    en: 'Reception Starts',
    ch: '婚宴开始时间',
  },
  homePage: {
    en: 'Home Pages',
    ch: '主頁',
  },
  dressCode: {
    en: 'DressCode: Formal',
    ch: '着装要求： 正式',
  },
  guestName: {
    en: 'Guest Name',
    ch: '宾客姓名'
  },
  date: {
    en: 'SATURDAY 07-10-2023',
    ch: '2023年10月7日 星期六'
  },
  rsvpReceived: {
    en: 'RSVP Received.',
    ch: '已收到请柬回复'
  },
  allNameError: {
    en: 'Please Enter a Name for every guest.',
    ch: '请输入每位客人的姓名。'
  },
  invalidEmailPhone: {
    en: 'Please Enter a valid Email or Phone Number.',
    ch: '请输入有效的电子邮件或电话号码。'
  },
  ceremonyText: {
    en: 'Ceremony',
    ch: '婚礼仪式',
  },
  receptionText: {
    en: 'Reception',
    ch: '婚宴',
  },
  nameText: {
    en: 'Name',
    ch: '姓名'
  },
  emailText: {
    en: 'Email',
    ch: '电子邮件'
  },
  phoneText: {
    en: 'Phone Number',
    ch: '电话号码'
  },
  addGuest: {
    en: 'Add Guest',
    ch: '添加'
  },
}

export const catering = {
  title: {
    en: 'Catering Select',
    ch: '婚宴餐饮选择',
  },
  selectFor: {
    en: 'Selection for',
    ch: '餐饮选择',
  },
  menuType: {
    en: 'Menu Type',
    ch: '菜单类型',
  },
  Adult: {
    en: 'Adult',
    ch: '成人',
  },
  Kids: {
    en: 'Kids',
    ch: '儿童',
  },
  None: {
    en: 'None',
    ch: '无',
  },
  menuTooltip: {
    en: 'Kids Menu is Available for kids under the age of 12, If you are bringing an infant/small child, please select None and we will reserve the seats for your baby.',
    ch: '儿童菜单适用于12岁以下的儿童，如果您携带婴儿/小孩，请选择无，我们将为您的宝宝保留座位。',
  },
  selectConfirm: {
    en: 'Selection Confirmation',
    ch: '选择确认',
  },
  selectedMenu: {
    en: 'Selected Menu',
    ch: '所选菜单',
  },
  special: {
    en: 'Special Requests: (Optional)',
    ch: '特殊要求：（可选）',
  },
  specialPlaceholder: {
    en: "High chair request, Special Dietery request ... etc.",
    ch: "儿童高脚椅、特殊饮食要求...等",
  },
  close: {
    en: 'Close',
    ch: '关闭',
  },
  prev: {
    en: 'Previous',
    ch: '上个',
  },
  next: {
    en: 'Next',
    ch: '下个',
  },
  submit: {
    en: 'Submit',
    ch: '提交',
  },
  reset: {
    en: 'Reset',
    ch: '重置',
  },
  noGuest: {
    en: 'No attendee for reception, Please submit a RSVP first.',
    ch: '没有婚宴的参加者, 请先提交回复',
  }
}

export const cateringMenuCN = {
  adult: {
    soup: [
      {
        name: '番茄汤',
        description: '碎番茄，脆罗勒',
        diet: ['vg', 'gf'],
      },
      {
        name: '泰式椰奶汤',
        description: '脆野米，香菜',
        diet: ['v', 'gf'],
      },
      {
        name: '龙虾汤',
        description: '烤栗子，白兰地奶油',
        diet: ['gf'],
      }
    ],
    appetizer: [
      {
        name: '羽衣甘蓝',
        description: '佩克利诺干酪、松子、松露蜂蜜油醋汁',
        diet: ['vg'],
      },
      {
        name: '烤椰菜花',
        description: '薄荷、南瓜籽、榛果、鹰嘴豆',
        diet: ['v', 'gf'],
      },
      {
        name: '意大利炖饭',
        description: '青豌豆、西洋菜，黑松露奶油',
        diet: ['vg', 'gf'],
      }
    ],
    main: [
      {
        name: '智利海鲈鱼',
        description: '欧防风酱、南瓜籽、榛果、椰菜花',
        diet: ['gf'],
      },
      {
        name: '甜椒菲力牛排',
        description: '公爵夫人土豆、红甘蓝泥、红酒汁',
        diet: ['gf'],
      },
      {
        name: '祖传番茄',
        description: '榛子奶酪、大蒜酸辣酱、芥菜',
        diet: ['v', 'gf', 'n'],
      },
      {
        name: '焖烧牛肋骨',
        description: '松露、蓝色玉米粥、红烧蔬菜',
        diet: ['gf'],
      },
    ],
    dessert: [
      {
        name: '焦糖布丁',
        description: '洋甘菊饼干',
        diet: ['vg'],
      },
      {
        name: '苹果姜意式奶冻',
        description: '糖煮苹果、姜酥碎',
        diet: ['gf'],
      },
      {
        name: '巧克力浓缩咖啡挞',
        description: '巧克力块、巧克力奶油',
        diet: [],
      }
    ]
  },
  kids: {
    appetizer: [
      {
        name: '蕃茄汤',
        description: '罗勒',
        diet: ['vg', 'gf'],
      }
    ],
    main: [
      {
        name: '肉丸意大利面',
        description: '',
        diet: ['p'],
      }
    ],
    dessert: [
      {
        name: '圣代',
        description: '香草、巧克力、草莓冰淇淋、鲜奶油、糖屑、巧克力酱',
        diet: [],
      }
    ]
  }
};

export const menuCourseMapCN = {
  soup: '开胃菜(汤)',
  appetizer: '头盘',
  main: '主菜',
  dessert: '甜品'
};

export const dietDescriptionCN = {
  v: {
    description: '純素',
    icon: 'fa-solid fa-leaf',
    color: 'green',
  },
  vg: {
    description: '素食',
    icon: 'fa-solid fa-seedling',
    color: 'darkgreen',
  },
  gf: {
    description: '无麸质',
    icon: 'fa-solid fa-wheat-awn',
    color: 'chocolate',
  },
  df: {
    description: '无奶',
    icon: 'fa-solid fa-cow',
    color: 'black',
  },
  n: {
    description: '含坚果',
    icon: '',
    color: 'brown',
  },
  p: {
    description: '含猪肉',
    icon: 'fa-solid fa-piggy-bank',
    color: 'mistyrose',
  },
};

export const main = {
  ourStory: {
    en: 'Our Story',
    ch: '我们的故事',
  },
  products: {
    en: 'Products',
    ch: '商品',
  },
  faq: {
    en: 'FAQ',
    ch: '常问问题',
  },
  contact: {
    en: 'Contact Us',
    ch: '联系我们',
  },
  comingSoon: {
    en: 'Coming Soon...',
    ch: '即将推出...'
  },
  groom: {
    en: 'Groom',
    ch: '新郎'
  },
  bride: {
    en: 'Bride',
    ch: '新娘'
  },
  faqLong: {
    en: 'Frequently Asked Questions',
    ch: '常问问题'
  }
};

export const story = {
  mainTitle: {
    en: 'Our Story',
    ch: '我们的故事'
  },
  title1: {
    en: 'We met',
    ch: '相识',
  },
  title2: {
    en: 'We got together',
    ch: '相知',
  },
  title3: {
    en: 'The Journey',
    ch: '相恋'
  },
  title4: {
    en: 'The Proposal',
    ch: '求婚'
  },
  title5: {
    en: 'The Wedding',
    ch: '结婚'
  },
  story1: {
    en: `We first met in 2015 at the restaurant Iris's sister works at. 
      Bryan is a regular at the restaurant and Iris caught his eyes the first time they met. 
      We would occasionally see each other at the restaurant, but we never got to know each other well enough, 
      despite Bryan was the MC at Iris's sister's wedding in 2016. `,
    ch: `我们第一次见面是在2015年在Iris姐姐工作的餐厅。
      Bryan是餐厅的常客，他第一眼看到Iris时已经留下了深刻的印象。
      我们会偶尔会在餐厅见到面，但我们还是没有机会更进一步了解对方，
      即便Bryan在2016年时当过Iris姐姐婚宴的主持人。`
  },
  story2: {
    en: `Fast forward to the latter half of 2019, 
      Bryan started to visit the restaurant Iris works at regularly again. 
      This time around, things turn out differently and we started talking more. 
      We exchanged contacts and went out a couple times, 
      and eventually started our relationship at the beginning of 2020. `,
    ch: `快进到2019年下半年，
      Bryan又开始定期光顾Iris工作的这家餐厅。
      这一次，情况有所不同，我们聊天比以前更多。
      我们交换了联系方式，也出去过几次，
      最终在 2020 年初开始了我们的关系。`
  },
  story3: {
    en: `We started our relationship during the pandemic, 
      but it did not stop us from getting close. 
      We both agreed that it allowed us to have more in depth discussions and talks, 
      and helped us grow closer than we would have without it. 
      We also introduced each other to our families and friends, and went out for many dates. `,
    ch: `我们的关系是在疫情期间开始的，
      但这并不妨碍我们靠近。
      我们都同意，疫情让我们能够进行更深入的对话并了解对方，
      比起没有它时，我们变得更加亲密。
      我们还将彼此介绍给我们的家人和朋友，并出去约会了好几次。`
  },
  story4: {
    en: `Just before valentine's day of 2022, 
      Bryan secretly bought the engagement ring and planned the 2 years anniversary trip with Iris in Victoria Island. 
      Bryan surprised Iris with a bouquet of roses alongside a horse carriage ride, 
      and proposed to Iris when they finished the ride. `,
    ch: `就在2022年情人节之前，
      Bryan偷偷买了订婚戒指，并计划与Iris一起去维多利亚岛进行两周年纪念之旅。
      Bryan给了Iris一个惊喜，送了一束玫瑰給Iris并带了她去坐马车。
      并在他们坐完马车后向Iris求婚了。`
  },
  story5: {
    en: `Over a year after our engagement, 
      we are excited to invite you and your family to join us on our special day. 
      We hope to see your at our wedding reception on October 7th, 2023 at The Vancouver Club! `,
    ch: `我们订婚一年多后，
      我们诚意邀请您和您的家人出席我们特別的日子。
      我们希望在 2023 年 10 月 7 日在 温哥华俱乐部 举行的婚宴上见到您！`
  },
}

export const faq = [
  {
    question: {
      en: 'What time are the events?',
      ch: '婚礼活动什么时间举行？',
    },
    answer: {
      en: 'Our Cocktail Hour starts at 05:30 PM, and the Wedding Reception starts at 06:30 PM on Oct 7, 2023.',
      ch: '2023 年 10 月 7 日，我们的鸡尾酒时间于下午 05:30 开始，婚宴于下午 06:30 开始。',
    }
  },
  {
    question: {
      en: 'When is the RSVP deadline?',
      ch: '回复截止日期是什么时候？',
    },
    answer: {
      en: 'September 14, 2023',
      ch: '2023 年 9 月 14 日。',
    }
  },
  {
    question: {
      en: 'Who can I bring with me?',
      ch: '我可以带谁一起去？',
    },
    answer: {
      en: 'Feel free to bring your family and partner with you, including your kids! Just remember to include them in your RSVP!',
      ch: '欢迎带上您的家人和伴侣，包括您的孩子！请记得将他们包含在您的请柬回复中！',
    }
  },
  {
    question: {
      en: 'Where is the venue? Where should I park?',
      ch: '婚宴场地在哪里？我应该在哪里停车？',
    },
    answer: {
      en: 'Please see the map attached for parking spots and venue location.',
      ch: '请参阅随附的地图。',
    }
  },
]
import React from "react";
import {useSelector, useDispatch} from 'react-redux';
import {setLanguageEN} from '../../redux/appSlice';

function MainLogo(props) {
  const dispatch = useDispatch();
  const languageEN = useSelector((state) => {
    return state.app.languageEN;
  });

  return (
    <div className="mx-auto col-10 prevent-select">
      <a className="d-flex justify-content-center" 
        id="main-logo-link" 
        onClick={() => { window.scrollTo({ top: 0, left: 0 }) }}
      >
        <img className="d-flex" id="main-logo" src="/img/logo.png"></img>
      </a>
      <button 
        type='button'
        className='btn btn-transparent d-flex justify-content-center text-center align-items-center'
        style={{ top: 5, right: 5, height: '40px', width: 'auto', position: 'absolute', background: 'transparent', zIndex: 99 }}
        onClick={() => {
            dispatch(setLanguageEN(!languageEN))
          }
        }
      >
        <img className='d-inline-block prevent-select' src='/img/language.png' style={{height: '100%', width: 'auto'}}/>
      </button>
    </div>
  );
}

export { MainLogo };

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Switch, Modal, Button, TextField, Select, MenuItem, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Loader } from "../GeneralComponents";

const LOCAL_URL = ['http://localhost:3001', 'http://127.0.0.1:3001', 'http://192.168.50.185:3001'];
const LOCAL_TEST = LOCAL_URL.includes(window.location.origin);
const CLOUD_IMAGE_BASE_ROUTE = 'https://storage.googleapis.com/lynsis-webapp.appspot.com/';

const DEFAULT_EMPTY_ITEM = {
  "name": {
    "ch": "",
    "en": "",
  },
  "priceInCents": 0,
  "description": {
    "ch": "",
    "en": "",
  },
  "productCategory": [],
  "images": [],
  "archived": false,
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function ItemModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [item, setItem] = useState({ ...DEFAULT_EMPTY_ITEM });
  const [submitStatus, setSubmitStatus] = useState('pending');
  // submitStatus:  "pending" : Filling form
  //                "submitting" : Submitting
  //                "success" : Submission Completed
  //                "error" : Error submission
  const [modalError, setModalError] = useState('');
  const [uploadedImages, setUploadedImages] = useState([]);
  const modalOpened = props.modalOpened;
  const productData = props.productData;
  const itemData = props.itemData;


  useEffect(() => {
    // Reset State of Edit Modal
    setSubmitStatus('pending');
    if (props.editItemId != '' && Object.keys(itemData).includes(props.editItemId)) {
      setItem({
        ...itemData[props.editItemId],
      });
      props.handleModalOpen(true);
    } else {
      setItem({
        ...DEFAULT_EMPTY_ITEM,
      });
    }
    setModalError('');
  }, [props.editItemId]);

  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: '800px',
    maxHeight: '100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflowY: 'auto',
    p: 2,
  };

  function handleChange(event) {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const nestedKeys = name.split('.');
    if (nestedKeys.length == 2) {
      setItem((values) => {
        return {
          ...values,
          [nestedKeys[0]]: {
            ...values[nestedKeys[0]],
            [nestedKeys[1]]: value,
          }
        };
      });
    } else {
      setItem((values) => {
        return {
          ...values,
          [name]: name == 'priceInCents' ? parseInt(parseFloat(value) * 100) : value,
        };
      });
    }
  }

  async function handleUploadImage(e) {
    const files = e.target.files;
    const resizedFiles = [];
    for (let i = 0; i < files.length; i++){
      const targetFile = files[i];
      const uploadedFileName = targetFile.name;
      const resizedImage = await resizeImageBase64(targetFile, 800);

      resizedFiles.push({
        fileName: uploadedFileName,
        image: resizedImage,
      })
    }
    setUploadedImages((value) => {
      return [...value, ...resizedFiles]
    })
  }

  async function postCreateItem(data) {
    let baseUrl = "";
    if (LOCAL_TEST) {
      let currentUrl = window.location.origin.slice(0, -5);
      baseUrl = `${currentUrl}:5000`;
    }

    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };
      const url = `${baseUrl}/api/createItem`;
      const response = await fetch(url, options);
      const result = await response.json();

      return result
    } catch (error) {
      console.error(error);
    }
  }

  async function resizeImageBase64(file, maxSize) {
    var reader = new FileReader();
    var image = new Image();
    var canvas = document.createElement('canvas');
    var dataURItoBlob = function (dataURI) {
      var bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
        atob(dataURI.split(',')[1]) :
        decodeURI(dataURI.split(',')[1]);
      var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
      var max = bytes.length;
      var ia = new Uint8Array(max);
      for (var i = 0; i < max; i++)
        ia[i] = bytes.charCodeAt(i);
      return new Blob([ia], { type: mime });
    };
    var resize = function () {
      var width = image.width;
      var height = image.height;
      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      }
      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(image, 0, 0, width, height);
      var dataUrl = canvas.toDataURL('image/jpeg');
      return dataUrl;
    };
    return new Promise((ok, no) => {
      if (!file.type.match(/image.*/)) {
        no(new Error("Not an image"));
        return;
      }
      reader.onload = function (readerEvent) {
        image.onload = function () { return ok(resize()); };
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  async function handleSubmit() {
    setSubmitStatus('submitting');
    const data = item;
    data.newImages = uploadedImages;
    const response = await postCreateItem(data);
    console.log(response);
    if (response.success) {
      setSubmitStatus('success');
      props.refreshTableData();
    } else {
      setSubmitStatus('error');
    }
  }

  function modalClose() {
    setSubmitStatus('pending');
    props.setTargetId('');
    setUploadedImages([]);
    setModalError('');
    props.handleModalOpen(false);
  }

  if (submitStatus != 'pending') {
    return (
      <Modal
        open={modalOpened}
        onClose={modalClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={boxStyle}>
          <div className='flex-row mb-3 col-12 px-1 text-center'>
            {submitStatus == 'submitting' && <Loader />}
            {submitStatus == 'success' && (
              <div>
                <div style={{ color: 'green', fontSize: '5vw' }}>
                  <FontAwesomeIcon icon='fa-solid fa-circle-check' />
                </div>
                <span>Submission Completed.</span>
              </div>
            )}
            {submitStatus == 'error' && (
              <div>
                <div style={{ color: 'red', fontSize: '5vw' }}>
                  <FontAwesomeIcon icon='fa-solid fa-circle-xmark' />
                </div>
                <span>An error has occured, please try again.</span>
              </div>
            )}
          </div>
          {submitStatus != 'submitting' && (
            <div style={{ marginTop: '20px' }} className='flex-row mb-3 col-12 px-1'>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                <Button variant='contained' color='success' onClick={modalClose}>
                  Close
                </Button>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    );
  }

  return (
    <Modal
      open={modalOpened}
      onClose={modalClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={boxStyle}>
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
          <h3>
            <strong>{`${item?.id ? 'Edit' : 'Create'}`} Item (SKU)</strong>
          </h3>
        </div>
        <div className='row px-1'>
          <div className='flex-row mb-3 col-6'>
            <TextField
              size='small'
              fullWidth
              type='text'
              name='name.en'
              value={item.name.en}
              onChange={handleChange}
              label="Item Name (EN)"
              variant="outlined"
            />
          </div>
          <div className='flex-row mb-3 col-6'>
            <TextField
              size='small'
              fullWidth
              type='text'
              name='name.ch'
              value={item.name.ch}
              onChange={handleChange}
              label="Item Name (CH)"
              variant="outlined"
            />
          </div>
        </div>
        <div className='flex-row mb-3 col-12 px-1'>
          <TextField
            size='small'
            fullWidth
            type='text'
            name='description.en'
            value={item.description.en}
            onChange={handleChange}
            label="description (EN)"
            variant="outlined"
          />
        </div>
        <div className='flex-row mb-3 col-12 px-1'>
          <TextField
            size='small'
            fullWidth
            type='text'
            name='description.ch'
            value={item.description.ch}
            onChange={handleChange}
            label="description (CH)"
            variant="outlined"
          />
        </div>
        <div className='row px-1'>
          <div className='flex-row mb-3 col-6'>
            <FormControl fullWidth>
              <InputLabel>Product Category</InputLabel>
              <Select
                id="item-category"
                multiple
                value={item.productCategory}
                onChange={handleChange}
                input={<OutlinedInput label="Product Category" />}
                name="productCategory"
                label="Product Category"
              >
                {Object.keys(productData).map((productId) => {
                  const product = productData[productId];
                  return (
                    <MenuItem
                      key={product.id}
                      value={product.id}
                    >
                      {product.name.en} {product.name.ch}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>
          <div className='flex-row mb-3 col-6'>
            <FormControl fullWidth>
              <InputLabel htmlFor="itme-price">Price</InputLabel>
              <OutlinedInput
                id="itme-price"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                label="Price"
                name="priceInCents"
                type="number"
                value={item.priceInCents/100}
                onChange={handleChange}
              />
            </FormControl>
          </div>
        </div>
        <div className='flex-row mb-3 col-12 px-1'>
          <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
            Upload Images
            <VisuallyHiddenInput type="file" accept="image/*" multiple onChange={handleUploadImage}/>
          </Button>
        </div>
        <div className="d-flex flex-row w-100" id='uploaded-images'>
          {
            item.images.map((imagePath, index) => {
              return (
                <div
                  key={index} 
                  style={{
                    display: 'flex',
                    height: '200px',
                    width: '200px',
                    maxWidth: '200px',
                    padding: '10px',
                    backgroundImage: `url(${CLOUD_IMAGE_BASE_ROUTE}${imagePath})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                  }}
                />
              )
            })
          }
          {uploadedImages.map((image, index) => {
            return (
              <Button 
                key={index} 
                style={{
                  height: '200px',
                  maxWidth: '200px',
                  padding: '10px',
                  backgroundImage: `url(${image.image})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center'
                }}
                onClick={()=>{
                  setUploadedImages((value) => {
                    return [...value.filter((item, i) => {
                      return i != index
                    })]
                  })
                }}
              >
                {image.fileName} {index}
                <FontAwesomeIcon icon='fa-solid fa-circle-xmark' />
              </Button>
            )
          })}
        </div>
        {item?.id &&
          <div className='col-12 px-1 mb-3'>
            <div style={{ textAlign: 'center', padding: '5px' }}>
              Archived
              <Switch
                name='archived'
                key={item.archived}
                checked={item.archived}
                onChange={handleChange}
              />
            </div>
          </div>
        }
        <div className='flex-row mb-3 col-12 px-1'>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            <Button variant='outlined' color='error' onClick={modalClose}>
              Cancel
            </Button>
            <Button variant='contained' color='primary' onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export { ItemModal };

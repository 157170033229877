import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from 'react-redux';
import { rsvp, main } from '../language';


function Contact(props) {
  const languageEN = useSelector((state) => {
    return state.app.languageEN;
  });

  const language = languageEN ? 'en' : 'ch';
  return (
    <div className="col-12 justify-content-center w-100 contact pb-5 px-0" id='contact'>
      <div className="col-12 p-0 text-center contact-title prevent-select">{main.contact[language]}</div>
      <div className='d-flex col-12 text-center justify-content-center align-items-center prevent-select'>
        <p>Please Contact us by phone or social media directly to place an order, our web store will be available soon!</p>
      </div>
      <div className="d-flex col-xl-8 col-lg-8 col-12 text-center mx-auto justify-content-around">
        <div className="col-8 d-flex p-1 flex-row">
            <div className="col-4 p-2">
              <img className='contact-img' src='./img/qr_ins.png' />
              <p>Instagram</p>
            </div>
            <div className="col-4 p-2">
              <img className='contact-img' src='./img/qr_wechat.png' />
              <p>WeChat</p>
            </div>
            <div className="col-4 p-2">
              <img className='contact-img' src='./img/qr_xhs.png' />
              <p>小紅書</p>
            </div>
            <div className="col-4 p-2 d-flex flex-column justify-content-center d-flex align-content-between flex-wrap">
              <a
                href="https://api.whatsapp.com/send?phone=17783165050"
                rel="noreferrer"
                target="_blank"
                className='btn m-1 p-1 flex-grow-1 text-success'
              >
                <FontAwesomeIcon
                  icon="fa-brands fa-whatsapp"
                  className='d-inline-block h-100'
                />
              </a>
              <a
                href='tel:+17783165050'
                rel="noreferrer"
                className='btn m-1 p-3 flex-grow-1 text-info'
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-phone"
                  className='d-inline-block h-100'
                />
              </a>
            </div>
        </div>
      </div>
    </div>
  );
}

export { Contact };

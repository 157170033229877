import React, { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainNav } from "./MainNav";
import { Footer } from "./MainFooter";
import { MainBody } from "./MainBody";
import { MainLogo } from "./MainLogo";
import Box from '@mui/material/Box';

function Main(props) {
  useEffect(() => {
    document.title = "Lynsis Event & Floral";
    const contentBody = document.getElementById("content-body");
    contentBody.addEventListener('scroll',
      throttle(() => {
        const topButton = document.getElementById("top-btn");
        const scrollDistance = contentBody.scrollTop;
        const contentHeight = contentBody.offsetHeight;
        if (scrollDistance > contentHeight / 2) {
          topButton.classList.add("show-top-btn");
        } else {
          topButton.classList.remove("show-top-btn");
        }
      }, 150)
    )
  }, []);

  function throttle(callbackFn, limit) {
    let wait = false;
    return function () {
      if (!wait) {
        callbackFn.call();
        wait = true;
        setTimeout(() => {
          wait = false;
        }, limit);
      }
    }
  }

  return (
    <React.Fragment>
      <Box component='div' sx={{ flexDirection: 'column', display: 'flex', height: '100%' }}>
        <Box component='div' sx={{ flexShrink: 0 }}>
          <MainNav screenSize="sm" />
          <MainLogo />
          <MainNav screenSize="lg" />
        </Box>
        <Box component='div' sx={{ flexGrow: 1, overflowY: 'auto', overflowX: 'hidden', marginBottom: '-20px'}} id='content-body'>
          <MainBody />
        </Box>
        <Box component='div' sx={{ flexShrink: 0}}>
          <Footer />
        </Box>
      </Box>
      <a id="top-btn" onClick={() => {
        const contentBody = document.getElementById("content-body");
        if (contentBody) {
          contentBody.scrollTo({ top: 0, behavior: 'smooth' })
        }
      }}>
        <FontAwesomeIcon icon='fa-solid fa-circle-arrow-up' />
      </a>
    </React.Fragment>
  );
}

export { Main };
